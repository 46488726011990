import React from "react"
import Layout from "../components/Layout";
import { StaticImage } from "gatsby-plugin-image";
import Grid from '@material-ui/core/Grid';
import '../styles/global.css';

const PersonInformation = ({nameAndDesignation, copdRole, description} ) => (
    <div style={{marginTop:'10px'}}> 
        <h4>{nameAndDesignation}</h4>
        <h6><strong>{copdRole}</strong></h6>
        <p>{description}</p>
    </div>
);

export default function About() {
  return (
    <React.Fragment>
      <Layout>
        <h1>Meet our Team</h1>
        <h2>MAP COPD Study Team</h2>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <StaticImage
              src={"../images/teamPhotos/MeiLanHan.jpeg"}
              alt="Person name"
              placeholder="blurred"
              width="400"
              height="500"
            />
            <PersonInformation
              nameAndDesignation="MeiLan Han, MD, MS"
              copdRole="MAP COPD Principal Investigator"
              description="Dr. Han is a leading COPD expert that has led multiple clinical trials at the University of Michigan and throughout the country."
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StaticImage
              src={"../images/teamPhotos/LabakiWassim.jpg"}
              alt="Person name"
              placeholder="blurred"
              width="400"
              height="500"
            />
            <PersonInformation
              nameAndDesignation="Wassim Labaki, MD, MS"
              copdRole="MAP COPD Co-Investigator"
              description="Dr. Labaki is a provider in the multidisciplinary COPD Clinic at the University of Michigan and has been involved in several COPD clinical trials."
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StaticImage
              src={"../images/teamPhotos/JefferyCurtis.png"}
              alt="Person name"
              placeholder="blurred"
              width="400"
              height="500"
            />
            <PersonInformation
              nameAndDesignation="Jeffrey Curtis, MD"
              copdRole="MAP COPD Co-Investigator"
              description="Dr. Curtis is a provider at the VA Hospital and at the University of Michigan. He is an international expert in COPD pathogenesis and clinical trials."
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StaticImage
              src={"../images/teamPhotos/CatherineMeldrum.jpg"}
              alt="Person name"
              placeholder="blurred"
              width="400"
              height="500"
            />
            <PersonInformation
              nameAndDesignation="Catherine Meldrum, PhD, MS, RN, CCRC"
              copdRole="MAP COPD Co-Investigator"
              description="Catherine has a Master’s Degree in Clinical Research and a PhD in Nursing.  She has been involved in clinical trials for over 20 years in a focus on COPD."
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StaticImage
              src={"../images/teamPhotos/GretchenBautista.jpg"}
              alt="Person name"
              placeholder="blurred"
              width="400"
              height="500"
            />
            <PersonInformation
              nameAndDesignation="Gretchen Bautista"
              copdRole="Project Manager"
              description="Gretchen is a Clinical Research Project Manager with over 20 years of experience in clinical research trials, NIH studies and industry sponsored trials."
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StaticImage
              src={"../images/teamPhotos/CrystalCutlip.png"}
              alt="Person name"
              placeholder="blurred"
              width="400"
              height="500"
            />
            <PersonInformation
              nameAndDesignation="Crystal Cutlip"
              copdRole="Study Coordinator"
              description="Crystal is a Clinical Subjects Coordinator with almost 17 years of patient care experience with the last 4 years being in Pulmonary Research."
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StaticImage
              src={"../images/teamPhotos/JaideHawn.jpg"}
              alt="Person name"
              placeholder="blurred"
              width="400"
              height="500"
            />
            <PersonInformation
              nameAndDesignation="Jaide Hawn"
              copdRole="Study Coordinator"
              description="Jaide is a Clinical Subjects Coordinator and has experience in academic, pre-clinical and clinical research trials."
            />
          </Grid>
        </Grid>
        <p className='welcomeMatter'>To learn more about <b>MAP COPD:</b> </p>
        <p className='aboutUsMatter'><b>Phone: </b><a href="tel:8443341234" className='callLink'>(844) 334-1234</a></p>
        <p className='aboutUsMatter'><b>Email : </b><a href='mailto:mapcopdstudy@med.umich.edu?subject=Request%20information%20about%20earcly%20COPD'  target="_blank" rel="noopener noreferrer" className='linkInParagraph'>mapcopdstudy@med.umich.edu</a></p>
      </Layout>
    </React.Fragment>
  )
}
